<script lang="ts">
	import Icon from "./Icon.svelte";
	import cross from "../../../core/assets/icons/cross.svg?raw";
	import { scrollLockStore } from "../stores/scrollLockStore.js";
	import { createEventDispatcher, onMount } from "svelte";

	let dialogClass = "";
	export { dialogClass as class };
	export let show = false;

	const dispatch = createEventDispatcher();

	let dialog = null as HTMLDialogElement | null;
	$: {
		if (dialog) {
			if (show && !dialog.open) {
				dialog.showModal();
			}
			if (!show && dialog.open) {
				dialog.close();
			}
		}
	}

	$: {
		if (show && !$scrollLockStore) {
			scrollLockStore.set(true);
		}
	}
	$: {
		if (!show) {
			scrollLockStore.set(show);
		}
	}

	function onClose(): void {
		show = false;
		dispatch("close");
	}

	function closeOnBackdrop(event: MouseEvent): void {
		if (!event.target) {
			return;
		}

		if (!(event.target instanceof Node)) {
			return;
		}

		if (event.target.nodeName === "DIALOG") {
			onClose();
		}
	}

	// Not using on:click because svelte complains about accessibility (non-interactable elements with a mouse/keyboard event listener).
	onMount(() => {
		dialog?.addEventListener("click", closeOnBackdrop);
		return (): void => {
			dialog?.removeEventListener("click", closeOnBackdrop);
		};
	});
</script>

<dialog
	bind:this={dialog}
	class="container bg-transparent !px-0 backdrop:fixed backdrop:inset-0 backdrop:backdrop-blur-sm"
	on:close={onClose}
>
	<div class="mx-auto rounded-lg bg-white p-6 {dialogClass}">
		<div class="mb-4 flex items-start gap-2 {$$slots.title ? 'justify-between' : 'justify-end'}">
			{#if $$slots.title}
				<slot name="title" />
			{/if}
			<button class="group p-1" type="button" on:click={onClose}>
				<Icon icon={cross} class="group-hover:text-primary aspect-square h-4 transition-colors duration-300" />
			</button>
		</div>
		<div class="text-left">
			<slot />
		</div>
		{#if $$slots.actions}
			<div class="mt-6 lg:mt-8">
				<slot name="actions" />
			</div>
		{/if}
		{#if $$slots.footer}
			<div class="mt-6">
				<slot name="footer" />
			</div>
		{/if}
	</div>
</dialog>
